<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1">
        <el-input placeholder="企业名称" v-model="searchForm.firm_name" size="small" class="input-with-select"
                  style="width:200px;margin-left: 10px" clearable></el-input>
        <el-input placeholder="岗位名称" v-model="searchForm.job_name" size="small" class="input-with-select"
                  style="width:200px;margin-left: 10px" clearable></el-input>

        <el-select v-if="$store.state.pmid != 220" v-model="searchForm.experience" clearable placeholder="经验要求"
                   size="small"
                   style="width:130px;margin-left:10px">
          <el-option label="全部经验" value></el-option>
          <el-option :label="item.param_desc" v-for="(item, idx) in gzjy" :key="idx" :value="(idx)"></el-option>
        </el-select>
        <el-select v-else v-model="searchForm.profession" clearable placeholder="专业要求" size="small"
                   style="width:130px;margin-left:10px">
          <el-option label="全部专业" value></el-option>
          <el-option :label="item.param_desc" v-for="(item, idx) in professions" :key="idx" :value="(idx)"></el-option>
        </el-select>

        <el-select v-model="searchForm.eduaction" clearable placeholder="学历要求" size="small"
                   style="width:130px;margin-left:10px">
          <el-option label="全部学历" value></el-option>
          <el-option :label="item.param_desc" v-for="(item, idx) in xlyq" :key="idx" :value="(idx)"></el-option>
        </el-select>

        <el-select v-model="searchForm.pay" clearable placeholder="薪资要求" size="small"
                   style="width:130px;margin-left:10px">
          <el-option label="全部薪资" value></el-option>
          <el-option :label="item.param_desc" v-for="(item, idx) in xzfw" :key="idx" :value="(idx)"></el-option>
        </el-select>

        <el-select v-model="searchForm.check_status" clearable placeholder="审核状态" size="small"
                   style="width:130px;margin-left:10px">
          <el-option label="全部状态" value></el-option>
          <el-option label="审核中" :value="0"></el-option>
          <el-option label="审核通过" :value="1"></el-option>
          <el-option label="审核不通过" :value="2"></el-option>
          <el-option label="已关闭" :value="3"></el-option>
        </el-select>

        <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
                   @click="() => { page.current_page = 1; getList()}">搜索
        </el-button>
      </div>

      <div class="flexStart"
           style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">

        <el-button size="small" icon="el-icon-download" type="primary" @click="export_excel()">导出数据</el-button>
        <el-button v-if="$store.state.config.sync_data" icon="el-icon-refresh" style="margin-left: 10px;" size="small"
                   type="primary" @click="tongbuJava">迁移老版本
        </el-button>
      </div>

    </el-row>
    <div class="dividerBar"></div>

    <el-table v-tableFit :data="tableData" row-key="id" height="100%" size="small" stripe border
              @sort-change="sortChange"
              style="width:fit-content;width:100%;">
      <el-table-column type="index" label="序号" width="65" align="center"/>
      <el-table-column label="企业名称" prop="firm_name" show-overflow-tooltip width="180" align="center"/>
      <el-table-column label="岗位名称" prop="job_name" show-overflow-tooltip width="150" align="center"/>
      <el-table-column v-if="$store.state.pmid != 220" label="岗位类型" prop="job_type_name" show-overflow-tooltip
                       width="135" align="center"/>
      <el-table-column label="招聘人数(个)" prop="need_num" width="120" align="center"/>
      <el-table-column label="工作城市" prop="cityName" width="180" align="center"/>
      <el-table-column v-if="$store.state.pmid != 220" label="工作地址" prop="word_addr" show-overflow-tooltip
                       width="200" align="center"/>
      <el-table-column v-if="$store.state.pmid != 220" label="经验要求" prop="experience" width="100px" align="center">
        <template slot-scope="scope">{{ getDictText(gzjy, scope.row.experience) }}</template>
      </el-table-column>
      <el-table-column label="学历要求" prop="eduaction" width="100px" align="center">
        <template slot-scope="scope">{{ getDictText(xlyq, scope.row.eduaction) }}</template>
      </el-table-column>
      <el-table-column v-if="$store.state.pmid == 220" label="专业要求" prop="profession" width="120px" align="center">
        <template slot-scope="scope">{{ getDictText(professions, scope.row.profession) }}</template>
      </el-table-column>
      <el-table-column label="薪资待遇" prop="pay" show-overflow-tooltip width="160" align="center">
        <template slot-scope="scope">{{ getDictText(xzfw, scope.row.pay) }}</template>
      </el-table-column>
      <el-table-column label="审核状态" prop="check_status" width="100px" align="center">
        <template slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.check_status == 0" type="primary">审核中</el-tag>
          <el-tag size="mini" v-if="scope.row.check_status == 1" type="success">审核通过</el-tag>
          <el-tag size="mini" v-if="scope.row.check_status == 2" type="danger">审核不通过</el-tag>
          <el-tag size="mini" v-if="scope.row.check_status == 3" type="info">已关闭</el-tag>
        </template>
      </el-table-column>
      <el-table-column sortable="create_date" label="发布日期" prop="create_date" min-width="160px" align="center">
        <template slot-scope="scope">
          {{ scope.row.create_date | handleDate }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="220px" align="center">
        <template slot-scope="scope">
          <el-tag class="pointer" @click="() => {
            modelTitle = '查看'
            handleModify(scope.row)
          }" type="primary" size="small">查看
          </el-tag>
          <el-tag v-if="isAdmin && $store.state.pmid == 220" class="pointer" @click="() => {
            modelTitle = '编辑'
            handleModify(scope.row,'edit')
          }" type="primary" size="small" style="margin-left:10px;">编辑
          </el-tag>
          <el-tag class="pointer" v-if="scope.row.check_status === 0" @click="() => {
            modelTitle = '审核'
            spword = ''
            handleModify(scope.row)
          }" type="success" size="small" style="margin-left:10px;">审核
          </el-tag>
          <el-tag v-if="isAdmin" class="pointer" @click="handleDelete(scope.row)" type="danger" size="small"
                  style="margin-left:10px;">删除
          </el-tag>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
                   :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
                   :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>

    <el-dialog ref="ruleForm" :visible.sync="dialogForm" :close-on-click-modal="false" :destroy-on-close="true"
               custom-class="cus_dialog" width="1000px">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;">{{ modelTitle }}</span>
      </div>
      <el-form :model="formData" label-width="100px">
        <template v-if="$store.state.pmid != 220">
          <el-row>
            <el-col :span="8">
              <el-form-item label="企业名称">
                <el-input readonly v-model.trim="formData.firm_name" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="岗位名称">
                <el-input readonly v-model.trim="formData.job_name" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="岗位类型">
                <el-input readonly v-model.trim="formData.job_type_name" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="招聘人数">
                <el-input readonly v-model.trim="formData.need_num" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="工作城市">
                <el-input readonly v-model.trim="formData.cityName" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="工作地址">
                <el-input readonly v-model.trim="formData.word_addr" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="经验要求">
                <el-select disabled v-model="formData.experience" style="width: 100%">
                  <el-option :label="item.param_desc" v-for="(item, idx) in gzjy" :key="idx"
                             :value="item.param_value / 1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="学历要求">
                <el-select disabled v-model="formData.eduaction" style="width: 100%">
                  <el-option :label="item.param_desc" v-for="(item, idx) in xlyq" :key="idx"
                             :value="item.param_value / 1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="外语要求">
                <el-select disabled v-model="formData.english_level" style="width: 100%">
                  <el-option :label="item.param_desc" v-for="(item, idx) in wyyq" :key="idx"
                             :value="item.param_value / 1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="计算机要求">
                <el-select disabled v-model="formData.computer_level" style="width: 100%">
                  <el-option :label="item.param_desc" v-for="(item, idx) in jsjyq" :key="idx"
                             :value="item.param_value / 1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="薪资待遇">
                <el-select disabled v-model="formData.pay" style="width: 100%">
                  <el-option :label="item.param_desc" v-for="(item, idx) in xzfw" :key="idx"
                             :value="item.param_value / 1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="审核状态">
                <el-select disabled v-model="formData.check_status" style="width: 100%">
                  <el-option :label="item.param_desc" v-for="(item, idx) in gwshzz" :key="idx"
                             :value="item.param_value / 1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="职位描述">
                <el-input type="textarea" readonly :autosize="{ maxRows: 10 }" v-model.trim="formData.job_desc"
                          style="width: 100%;height:200px"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24" v-if="formData.check_status == 2">
              <el-form-item label="审核意见">
                <el-input type="textarea" readonly v-model.trim="formData.check_desc" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <template v-else>
          <el-row>
            <el-col :span="8">
              <el-form-item label="企业名称">
                <el-input :readonly="!isEdit" v-model.trim="formData.firm_name" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="岗位名称">
                <el-input :readonly="!isEdit" v-model.trim="formData.job_name" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="招聘人数">
                <el-input :readonly="!isEdit" v-model.trim="formData.need_num" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="工作城市">
                <el-cascader v-if="isEdit" class="input_css" v-model="formData.city"
                             ref="firstCascader"
                             :options="cityOptions"
                             :props="{ emitPath: false, checkStrictly: false }"
                             :show-all-levels="false" placeholder="请选择所在地区"
                             filterable clearable></el-cascader>
                <el-input v-else readonly v-model.trim="formData.cityName" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="学历要求">
                <el-select :disabled="!isEdit" v-model="formData.eduaction" style="width: 100%">
                  <el-option :label="item.param_desc" v-for="(item, idx) in xlyq" :key="idx"
                             :value="item.param_value / 1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="专业要求">
                <el-select :disabled="!isEdit" v-model="formData.profession" style="width: 100%">
                  <el-option :label="item.param_desc" v-for="(item, idx) in professions" :key="idx"
                             :value="item.param_value / 1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="开始日期">
                <el-date-picker v-if="isEdit"
                                v-model="formData.start_time"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期">
                </el-date-picker>
                <el-input v-else readonly v-model.trim="formData.start_time" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="结束日期">
                <el-date-picker v-if="isEdit"
                                v-model="formData.end_time"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期">
                </el-date-picker>
                <el-input v-else readonly v-model.trim="formData.end_time" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="薪资待遇">
                <el-select :disabled="!isEdit" v-model="formData.pay" style="width: 100%">
                  <el-option :label="item.param_desc" v-for="(item, idx) in xzfw" :key="idx"
                             :value="item.param_value / 1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="审核状态">
                <el-select disabled v-model="formData.check_status" style="width: 100%">
                  <el-option :label="item.param_desc" v-for="(item, idx) in gwshzz" :key="idx"
                             :value="item.param_value / 1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="福利待遇">
                <el-checkbox-group v-if="isEdit" v-model="formData.fldy" @change="handleCheckFldy">
                  <el-checkbox v-for="(item, idx) in fldys" :label="item.param_value" :key="idx">
                    {{ item.param_desc }}
                  </el-checkbox>
                </el-checkbox-group>
                <el-checkbox-group v-else v-model="formData.fldy">
                  <el-checkbox v-if="formData.fldy && formData.fldy.indexOf(item.param_value) > -1"
                               v-for="(item, idx) in fldys" :label="item.param_value" :key="idx" disabled>
                    {{ item.param_desc }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="formData.fldy && formData.fldy.indexOf('0') > -1">
            <el-col :span="24">
              <el-form-item label="其他福利">
                <el-input type="textarea" :readonly="!isEdit" :autosize="{ maxRows: 10 }" v-model.trim="formData.fldy_desc"
                          style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="职位描述">
                <el-input type="textarea" :readonly="!isEdit" :autosize="{ maxRows: 10 }" v-model.trim="formData.job_desc"
                          style="width: 100%;height:200px"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24" v-if="formData.check_status == 2">
              <el-form-item label="审核意见">
                <el-input type="textarea" readonly v-model.trim="formData.check_desc" style="width: 100%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-form>


      <div v-if="formData.check_status == 0 && modelTitle == '审核'" slot="footer" class="flexEnd" style="width: 100%;">
        <span style="white-space: nowrap;">审核意见：</span>
        <el-input v-model="spword" size="small" type="textarea" :autosize="{ minRows: 1 }" maxlength="200"
                  placeholder="审核驳回时请填写驳回原因哦~" style="margin-right: 20px;"></el-input>
        <el-button type="danger" @click="submitForm(2)" size="small">审核驳回</el-button>
        <el-button type="success" @click="submitForm(1)" size="small">审核通过</el-button>
      </div>

      <div v-if="modelTitle == '查看'" slot="footer" class="flexEnd" style="width: 100%;">
        <el-button type="primary" plain @click="dialogForm = false">取消</el-button>
      </div>
      <div v-if="isEdit" slot="footer" class="flexEnd" style="width: 100%">
        <el-button type="primary" @click="submitForm(3)">提交</el-button>
        <el-button @click="dialogForm = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import citys from "../../../utils/arae_value.js";
import util from "@/utils/util";

export default {
  data() {
    return {
      searchForm: {
        check_status: "",
        firm_name: "",
        job_name: "",
        experience: "",
        eduaction: "",
        city: "",
        pay: "",
        profession: "",
        orderBy: {},
      },
      tableData: [],
      dialogForm: false,
      formData: {},
      qyxz: [],
      xzfw: [],
      xlyq: [],
      gzjy: [],
      wyyq: [],
      jsjyq: [],
      gwshzz: [],
      fldys: [],
      professions: [],
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      spword: "",
      modelTitle: '',
      isAdmin: false,
      isEdit: false,
      cityOptions: []
    };
  },
  filters: {
    handleDate(value) {	// value是输入框的内容，也是要显示的内容
      if (value) {
        return value.substring(0, 10)
      }
    }
  },
  mounted() {
    this.cityOptions = citys
    this.getComType().then(rr => {
      this.getList();
    });
    setTimeout(() => {
      let roles = this.$store.state.teacher.sys_roles
      this.isAdmin = roles.filter(role => role.role_code.indexOf('admin') >= 0 || role.role_code == 'xx').length > 0
    }, 500)
  },
  methods: {
    getDictText(data, val) {
      return data.filter(item => item.param_value == val).map(m => m.param_desc).pop()
    },
    getComType() {
      return new Promise(resolve => {
        let salaryKey = window.pmid == 220 ? 'SALARY_RANGE_ZP' : 'SALARY_RANGE'
        this.$http
            .post("/api/sys_param_list", {
              param_type:
                  "'ENTER_INFO','" + salaryKey + "','EDUACTION','EXPERIENCE','ENGLISH_LEVEL','COMPUTER_LEVEL','GWSHZT','PROFESSIONS','FLDY'",
              used: 0
            })
            .then(res => {
              let qyxz = []; //企业性质
              let xzfw = []; //薪资类型
              let xlyq = []; //学历类型
              let gzjy = []; //工作经验
              let wyyq = []; //外语要求
              let jsjyq = []; //计算机要求
              let gwshzz = []; //计算机要求
              let professions = []
              let fldys = []
              for (let item of res.data) {
                if (item.param_type == "ENTER_INFO") {
                  qyxz.push(item);
                }
                if (item.param_type == salaryKey) {
                  item.param_desc = item.param_desc == '未知' ? '面议' : item.param_desc
                  xzfw.push(item);
                }
                if (item.param_type == "EDUACTION") {
                  xlyq.push(item);
                }
                if (item.param_type == "EXPERIENCE") {
                  gzjy.push(item);
                }
                if (item.param_type == "ENGLISH_LEVEL") {
                  wyyq.push(item);
                }
                if (item.param_type == "COMPUTER_LEVEL") {
                  jsjyq.push(item);
                }
                if (item.param_type == "GWSHZT") {
                  gwshzz.push(item);
                }
                if (item.param_type == "PROFESSIONS") {
                  professions.push(item);
                }
                if (item.param_type == "FLDY") {
                  fldys.push(item);
                }
              }
              this.qyxz = qyxz;
              this.xzfw = xzfw;
              this.xlyq = xlyq;
              this.gzjy = gzjy;
              this.wyyq = wyyq;
              this.jsjyq = jsjyq;
              this.gwshzz = gwshzz;
              this.professions = professions
              this.fldys = fldys
              resolve();
            });
      });
    },
    getList() {
      let data = {};
      data.check_status = this.searchForm.check_status;
      data.firm_name = this.searchForm.firm_name;
      data.job_name = this.searchForm.job_name;
      data.experience = this.searchForm.experience;
      data.eduaction = this.searchForm.eduaction;
      data.profession = this.searchForm.profession;
      data.city = this.searchForm.city;
      data.pay = this.searchForm.pay;
      data.page = this.page.current_page;
      data.pagesize = this.page.per_page;
      data.orderBy = this.searchForm.orderBy
      this.$http.post("/api/zp_job_info_list", data).then(res => {
        this.tableData = res.data.data;
        res.data &&
        res.data.data &&
        res.data.data.map(a => {
          if (a.city) {
            let areas = util.getParentAreaByValue(citys, a.city)
            if (areas && areas.length > 0) {
              a.cityName = areas.map(a => a.label).reverse().join('/')
            }
          }
        });

        this.page = res.data.page;
      });
    },
    export_excel() {
      let data = {};
      data.check_status = this.searchForm.check_status;
      data.firm_name = this.searchForm.firm_name;
      data.job_name = this.searchForm.job_name;
      data.experience = this.searchForm.experience;
      data.eduaction = this.searchForm.eduaction;
      data.city = this.searchForm.city;
      data.pay = this.searchForm.pay;
      this.$http.post("/api/export_job_info_list", data).then(res => {
        if (res.data.url) {
          window.open(res.data.url);
        }
      });
    },
    getParentId(list, iid) {
      if (list && list.length > 0) {
        for (let o of list) {
          if (o.value == iid) return o;
          const o_ = this.getParentId(o.children, iid);
          if (o_) return o_;
        }
      }
    },
    handleModify(row, type) {
      this.isEdit = type === 'edit';
      this.$http.post("/api/zp_job_info", {id: row.id}).then(res => {
        this.formData = res.data;
        if (res.data && res.data.city) {
          let areas = util.getParentAreaByValue(citys, res.data.city)
          if (areas && areas.length > 0) {
            this.formData.cityName = areas.map(a => a.label).reverse().join('/')
          }
          this.formData.fldy = this.formData.fldy ? this.formData.fldy.split(',') : [];
        }
        this.dialogForm = true;
      });
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    submitForm(st) {
      if (st == 3) {
        let formData = {...this.formData}
        formData.fldy = formData.fldy.join(',')
        formData.is_admin = 1
        this.$http.post('/api/zp_job_info_edit',formData).then(res => {
          this.$message.success('提交成功')
          this.getList()
          this.dialogForm = false
        })
      } else {
        if (st == 2 && !this.spword.trim()) {
          this.$message.error("请输入拒绝原因");
          return;
        }
        this.$confirm("确定审核?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          if (st == 2) {
            if (!this.spword) {
              this.$message.error("请输入拒绝原因");
              return;
            }
          }

          this.$http
              .post("/api/zp_job_info_audit", {
                id: this.formData.id,
                check_status: st,
                check_desc: this.spword
              })
              .then(res => {
                this.$message({
                  type: "success",
                  message: "审核成功!"
                });
                this.getList();
                this.dialogForm = false;
              });
        });
      }
    },
    tongbuJava() {
      this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let loadingInstance = this.$loading({
          lock: true,
          text: "已发送请求，请稍等...",
          spinner: 'el-icon-loading'
        })
        this.$http.post("/api/sync_job_info", {}).then(res => {
          loadingInstance.close()
          this.$message.success('后台同步中，请刷新查看')
          this.getList();
        }).catch(err => {
          this.$message.error('同步失败')
          loadingInstance.close()
        })

      }).catch(() => {

      });
    },
    handleDelete(row) {
      this.$confirm('此操作不可恢复，确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/api/zp_job_info_delete', {id: row.id}).then(res => {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
          this.getList();
        })
      })
    },
    sortChange({column, prop, order}) {
      let sortType = ""
      if (order === 'descending') {
        sortType = "desc"
      } else if (order === 'ascending') {
        sortType = "asc"
      }
      this.searchForm.orderBy[column.sortable] = sortType
      this.getList()
    },
    handleCheckFldy(val) {
      if (val.indexOf(0) < 0) {
        this.formData.fldy_desc = null;
      }
    },
  },
};
</script>

<style lang="less" scoped></style>